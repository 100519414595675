<template>
  <div class="container_con">
    <el-card>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="发票管理" name="1"></el-tab-pane>
            <el-tab-pane label="未开发票" name="2"></el-tab-pane>
        </el-tabs>
        <div class="search_box">
            
          <!-- <span class="mr_20">
            单位名称：
            <el-input
              v-model="queryParams.name"
              placeholder="请输入文字..."
              style="width:245px"
            />
          </span>
          <span class="mr_20">
            助记码：
            <el-input
              v-model="queryParams.name"
              placeholder="请输入文字..."
              style="width:245px"
            />
          </span>
          <span class="mr_20">
            单位编号：
            <el-input
              v-model="queryParams.name"
              placeholder="请输入文字..."
              style="width:245px"
            />
          </span> -->
          <span class="mr_20">
            单据编号：
            <el-input
              v-model="queryParams.sale_out_no"
              placeholder="单据编号(多个,分割)"
              style="width:245px"
            />
          </span>
          <span class="mr_20">
            客户名称：
            <el-input
              v-model="queryParams.customer"
              placeholder="客户名称(多个,分割)"
              style="width:245px"
            />
          </span>
          <span class="mr_20">
            发票号：
            <el-input
              v-model="queryParams.invoice_no"
              placeholder="请输入发票号"
              style="width:245px"
            />
          </span>
          <span class="mr_20">
            时间：
            <el-date-picker
              v-model="queryParams.time"
              type="daterange"  value-format="YYYY-MM-DD" 
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </span>
          <el-button type="primary" @click="handleQuery()">搜索</el-button>
          <el-button @click="resetQuery()">重置</el-button>
        </div>
        <el-button type="primary" style="margin-top:20px" v-if="have_auth('/financial/export') && activeName==1" :loading="is_export" @click="try_export">导出</el-button>
        <el-button type="primary" style="margin-top:20px" v-if="activeName==2 && have_auth('/financial/open')" :disabled="multiple" @click="try_add(1)" >开票</el-button>
        
        <div style="margin-top:20px">
          <el-table :data="list" v-loading="loading" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" v-if="activeName==2" />
            <el-table-column prop="sale_out_no" label="单据编号" />
            <el-table-column prop="merchant_name" label="客户名称" />
            <el-table-column prop="merchant_no" label="单位编号" />
            <el-table-column prop="amount" label="金额(元)" />
            <el-table-column prop="sale_out_date" label="时间" width="150" />
            <el-table-column prop="department_name" label="部门名称" width="180" />
            <el-table-column prop="admin_name" label="业务员" />
            <el-table-column prop="invoice_no" label="发票号" />
            <el-table-column prop="invoice_admin_name" label="操作员" />
            <el-table-column fixed="right" label="操作" width="100" v-if="activeName==1 &&( have_auth('/financial/edit') || have_auth('/financial/back'))">
              <template v-slot="scope">
                <el-button type="text" size="small" @click="try_add(2,scope.row)" v-if="have_auth('/financial/edit')">编辑</el-button>
                <el-button type="text" size="small" @click="handleBack(scope.row)" v-if="have_auth('/financial/back')">撤回</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      
    </el-card>
  </div>
  <pagination
    v-show="total>0"
    :total="total"
    v-model:page="queryParams.page"
    v-model:limit="queryParams.size"
    @pagination="getList"
  />
  
  
  <el-dialog v-model="dialogVisible" width="600px" append-to-body :show-close="false">
    <template #title>
      <div class="dialog_box">
        <div class="con_title"><span></span> {{op_type==1?'开票':'编辑'}}</div>
      </div>
    </template>
    <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="发票号：" prop="invoice_no">
          <el-input
            v-model="form.invoice_no"
            placeholder="请输入发票号" style="width:100%"
          />
        </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "financial",
  data() {
    return {
      activeName: "1",
      // 列表模板
      loading: false,
      // 查询参数
      queryParams: {
          page: 1,
          size: 10,
          sale_out_no: '',
          invoice_no:'',
          customer:'',
          time:''
      },
      total:0,
      list:[],
      dialogVisible:false,
      form:{},
      rules: {
        invoice_no: [
          { required: true, message: "发票号不能为空",trigger: "blur"}
        ]
      },
      is_ajax:false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      is_export:false,
      op_type:1
    };
  },
  created() {
      this.queryParams=window[this.$route.path] || {
        page: 1,
        size: 10,
        sale_out_no: '',
        invoice_no:'',
        customer:'',
        time:''
      }
    this.activeName = sessionStorage.getItem("activeName")
      ? sessionStorage.getItem("activeName")
      : this.activeName;
      this.getList();
  },
  watch: {
    $route(to,form) {
        window[form.path]=this.queryParams
    },
  },
  methods: {
      /** 撤回按钮操作 */
      handleBack(row) {
        const _this=this;
        const ids = row.id || this.ids;
        this.$confirm('是否确认撤回?', "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(function() {
            
            _this.$httpGet("/backend/SaleOut/withdrawInvoice", {id:ids}).then((res) => {
                if (res.status == 200) {
                    _this.getList();
                    _this.$message.success(res.message);
                } else {
                    _this.$message.error(res.message);
                }
            }).catch((err) => {
                console.log(err);
            });
          })
      },
      try_export(){
          if(this.is_export){return false;}
          this.is_export = true;
          let _data={...this.queryParams};
          _data.startTime=_data.time?_data.time[0]:'';
          _data.endTime=_data.time?_data.time[1]:'';
          this.$httpGet("/backend/SaleOut/invoice_export", _data).then((res) => {
              if (res.status == 200) {
                    var a = document.createElement('a');
                    let name=res.data.file.split('/')
                    var fileName = name[name.length-1];
                    a.download = fileName;
                    a.href = this.$http+'/'+res.data.file;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
              } else {
                  this.$message.error(res.message);
              }
              this.is_export = false;
          }).catch((err) => {
              console.log(err);
              this.is_export = false;
          });
      },
      // 多选框选中数据
      handleSelectionChange(selection) {
        this.ids = selection.map(item => item.id);
        this.single = selection.length != 1;
        this.multiple = !selection.length;
      },
    try_add(type,row){
        this.op_type=type;
        if(type==2){
          this.op_id=row.id;
          this.form.invoice_no=row.invoice_no;
        }
        this.dialogVisible=true;
    },
    /** 提交按钮 */
    submitForm: function(form) {
      this.$refs[form].validate((valid)  => {
        if (valid) {
          this.is_ajax=true;
          if(form=='form'){
            if(this.op_type==1){
              this.form.id=this.ids.join(',')
            }else{
              this.form.id=this.op_id
            }
            
            this.$httpPost("/backend/SaleOut/invoice", this.form).then((res) => {
                if (res.status == 200) {
                    this.$message.success(res.message);
                    this.form={};
                    this.dialogVisible=false;
                    this.getList()
                } else {
                    this.$message.error(res.message);
                }
                this.is_ajax=false;
            }).catch((err) => {
                console.log(err);
                this.is_ajax=false;
            });
          }
        }
      });
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.$router.push('/addFinancial?id='+row.id)
    },
    handleClick() {
      sessionStorage.setItem("activeName", this.activeName);
      this.getList();
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const _this=this;
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          
          _this.$httpGet("/backend/SaleOut/del", {ids:ids}).then((res) => {
              if (res.status == 200) {
                  _this.getList();
                  _this.$message.success(res.message);
              } else {
                  _this.$message.error(res.message);
              }
          }).catch((err) => {
              console.log(err);
          });
        })
    },
    /** 查询列表 */
    getList() {
        this.loading = true;
        let _data={...this.queryParams};
        _data.type=this.activeName;
        _data.startTime=_data.time?_data.time[0]:'';
        _data.endTime=_data.time?_data.time[1]:'';
        this.$httpGet("/backend/SaleOut/invoice_index",_data ).then((res) => {
            if (res.status == 200) {
                this.loading = false;
                this.list = res.data.data;
                this.total = res.data.total;
            } else {
                this.$message.error(res.message);
            }
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            this.loading = false;
        });
    },
    /** 搜索按钮操作 */
    handleQuery() {
        this.queryParams.page = 1;
        this.getList();
    },
    resetQuery(){
        this.queryParams={
            page:1,
            size:10,
            sale_out_no: '',
            invoice_no:'',
            customer:'',
            time:''
        }
        this.getList();
    }
  },
};
</script>

<style lang="scss" scoped>
.search_box {
  background: #f2f3f5;
  border-radius: 2px;
  padding: 15px;
}
.mr_20 {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 15px;
}
</style>
